import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const fullwidthHeaders = document.querySelectorAll(
    '.fullwidth-header__carousel'
  );

  if (0 < fullwidthHeaders.length) {
    fullwidthHeaders.forEach(function (carousel) {
      const slides = carousel.querySelectorAll('.swiper-slide');

      if (1 < slides.length) {
        carousel.classList.remove('single-slide');
        new Swiper(carousel, {
          direction: 'horizontal',
          pagination: {
            el: carousel.querySelector('.swiper-pagination'),
            clickable: true,
            type: 'fraction',
          },
          navigation: {
            nextEl: carousel.querySelector('.swiper-button-next'),
            prevEl: carousel.querySelector('.swiper-button-prev'),
          },
        });
      }
    });
  }
});
